import Plot from "react-plotly.js";
import supabaseCLP from "../supabase";
import { useState, useEffect } from "react";

export default function Dashboard() {
  const [reqData, setReqData] = useState([]);
  const [actData, setActData] = useState([]);

  const requiredUID = "1a:22:33:44:gf:ce";

  async function getReqData() {
    console.log("Fetching aggregated sensor data...");
    try {
      // load all data
      const { data: rawdata, error } = await supabaseCLP
        .from("Aggregated_Process_Data")
        .select("created_at,value")
        .eq("UID", "SOLL");
      setReqData(rawdata);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  async function getActData() {
    console.log("Fetching aggregated sensor data...");
    try {
      // load all data
      const { data: rawdata, error } = await supabaseCLP
        .from("Aggregated_Process_Data")
        .select("created_at,value")
        .eq("UID", "IST");
      setActData(rawdata);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  useEffect(() => {
    getReqData();
    getActData();
  }, []);

  // Main usermanagement return
  return (
    <div className="Function-Screen">
      <div className="Function-Screen-header">
        <h2>DASHBOARD</h2>
      </div>
      <div className="Function-Screen-main">
        <Plot
          className="resp-plotlyplot-width"
          data={[
            {
              x: reqData.map((e) => e.created_at),
              y: reqData.map((e) => e.value),
              name: "SOLL",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: "#097ec2" },
              line: { shape: "hv" },
            },
            {
              x: actData.map((e) => e.created_at),
              y: actData.map((e) => e.value),
              name: "IST",
              type: "scatter",
              mode: "lines+markers",
              marker: { color: "red" },
              line: { shape: "hv" },
              fill: "tonexty",
            },
            // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
          ]}
          layout={{
            title: "Supabase Data",
            responsive: true,
          }}
          useResizeHandler={true}
        />
        {/* <iframe
          height="400px"
          width="100%"
          src="https://dashboard.roehm-iot-cloud.com/d/3messendeBacken/3-messende-backen?orgId=1"
        /> */}
      </div>
    </div>
  );
}
