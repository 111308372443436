import { useEffect, useState } from "react";
import supabaseCLP from "../supabase";
import FloatingMSTCON from "./floatingMSTCON";

function RegisterBody() {
  return (
    <div className="Login-Body">
      {" "}
      <h1>Register</h1>
      <img src="./Icons/Symbole/User.png" alt="registerinicon" />
    </div>
  );
}

export default function StartScreen({ setLogin, setCurrentUser }) {
  const [modeLogin, setmodeLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userListData, setUserListData] = useState([]);

  // fetiching the data from supabase
  useEffect(() => {
    async function getUserlist() {
      try {
        const { data: UserDB, error } = await supabaseCLP
          .from("Userlist")
          .select("*");
        setUserListData(UserDB);
        // localStorage.setItem("userlist", JSON.stringify(UserDB));
      } catch (error) {
        alert(error);
      }
      // set the state of the internally used facts variable
    }
    // call the async fetch function to execute the function
    getUserlist();
  }, []);

  //   Submit handler of login form
  function handleSubmit(e) {
    e.preventDefault();

    // search for entries in userlist
    try {
      userListData.map((e) => {
        // console.log(e);

        if (e.username === username) {
          if (e.password === password) {
            if (e.approved) {
              // set current user for the sidebar configuration etc.
              setCurrentUser({
                userName: e.username,
                Role: e.role,
              });
              // login
              setLogin(true);
              localStorage.setItem("userlist", JSON.stringify(userListData));
              // clear the form
              setUsername("");
              setPassword("");
            } else {
              console.log("no auth");
              setUsername("");
              setPassword("");
              alert(
                "Unauthorisierter Zugriff. Bitte kontaktieren Sie Ihren Administrator."
              );
            }
          } else {
            console.log("no password");
            setUsername("");
            setPassword("");
            alert("Falsches Passwort oder Benutzername");
          }
        }
      });
    } catch (error) {
      alert(
        "Zur Zeit herrscht keine Verbindung zur erforderlichen Datenbank. Bitte sorgen Sie für eine ungestörte Internetverbindung. Danach laden Sie bitte die Startseite erneut und versuchen sich einzuloggen."
      );
    }
  }

  return (
    <>
      <FloatingMSTCON />
      <div className="startscreen">
        <div>
          <img src="./Icons/CLPPress2StartLoop_2.gif" />
        </div>
      </div>
      <div className="LoginFrame">
        <div className="LoginAndRegister">
          <div className="Login-Header">
            <button
              style={{
                backgroundColor: modeLogin ? "#097fc2" : "#888",
                color: modeLogin ? "#fff" : "#000",
                borderColor: modeLogin ? "#fff" : "#000",
              }}
              onClick={() => setmodeLogin(true)}
            >
              LOGIN
            </button>
            <button
              style={{
                backgroundColor: !modeLogin ? "#097fc2" : "#888",
                color: !modeLogin ? "#fff" : "#000",
                borderColor: !modeLogin ? "#fff" : "#000",
              }}
              onClick={() => setmodeLogin(false)}
            >
              REGISTER
            </button>
          </div>

          {modeLogin ? (
            <form onSubmit={handleSubmit}>
              <div className="Login-Body">
                <div>
                  <img src="./Icons/Symbole/User.png" alt="loginicon" />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center", // Horizontally center the inputs
                    rowGap: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="username"
                    required="true"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></input>
                  <input
                    type="password"
                    placeholder="password"
                    required="true"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                </div>
                <div className="Login-Footer">
                  <div>
                    <button>LOGIN</button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <RegisterBody />
          )}
        </div>
      </div>
    </>
  );
}
