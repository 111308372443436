import { useState, useEffect } from "react";

export default function Team({ currentUser }) {
  console.log(currentUser);
  // Main usermanagement return
  return (
    <div className="Function-Screen">
      <div className="Function-Screen-header">
        <h2>TEAM</h2>
      </div>
      <div className="Function-Screen-main">
        <p>Wecome {currentUser.userName}!</p>
      </div>
    </div>
  );
}
