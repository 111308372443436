import { createClient } from "@supabase/supabase-js";

// Project "CLP"
// Userlist
const supabaseUrl = "https://cqfixrkhzwhftlnhcobg.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNxZml4cmtoendoZnRsbmhjb2JnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI1MDI2NDUsImV4cCI6MjAzODA3ODY0NX0.pGHRVvEkpufzvrvsjsaVevVIDhMN1tOE7Y14Y6NdWps";

//   define the supabase client
const supabaseCLP = createClient(supabaseUrl, supabaseKey);

// export the supabase client
export default supabaseCLP;
