export default function FloatingMSTCON() {
  return (
    <div className="floating-mstcon">
      <p style={{ color: "#fff" }}>by</p>
      <img
        src="./Icons/MSTCON_Icon.png"
        onClick={() => (window.location.href = "https://www.mst-con.com")}
        alt="FloatingMSTCONIcon"
      />
    </div>
  );
}
