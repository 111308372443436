import { useEffect, useState } from "react";
import React, { useRef } from "react";

import Dummy from "./components/dummy";

import Header from "./components/header";
import Sidebar from "./components/sidebar";

import "./style.css";
import StartScreen from "./components/startscreen";
import UserManagement from "./components/usermanagement";
import Dashboard from "./components/dashboard";
import Aufgaben from "./components/aufgaben";
import Team from "./components/team";

const SidebarControlAppRoleFunctions = [
  {
    description: "Firma",
    img: "Icons/Symbole/Factory.png",
    reqRole: ["admin"],
  },
  {
    description: "User",
    img: "Icons/Symbole/UserManagement.png",
    reqRole: ["admin"],
  },
  {
    description: "Team",
    img: "Icons/Symbole/TeamEdit.png",
    reqRole: ["manager"],
  },
  {
    description: "Aufgaben",
    img: "Icons/Symbole/TaskEdit.png",
    reqRole: ["admin", "manager"],
  },
  {
    description: "Aufgabenliste",
    img: "Icons/Symbole/Tasklist.png",
    reqRole: ["admin", "operator"],
  },
  {
    description: "Dashboard",
    img: "Icons/Symbole/Dashboard.png",
    reqRole: ["admin", "manager"],
  },
];

function App() {
  // const [login, setLogin] = useState(() => {
  //   const savedLoginStatus = localStorage.getItem("login");
  //   return savedLoginStatus ? true : false;
  // });
  const [login, setLogin] = useState(false);
  const [currentFunction, setCurrentFunction] = useState("");
  const [currentUser, setCurrentUser] = useState([]);

  // Save login to localStorage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem("login", login);
  // }, [login]);

  return (
    <div>
      {!login ? (
        <StartScreen setLogin={setLogin} setCurrentUser={setCurrentUser} />
      ) : (
        <div className="container-grid-1">
          <Header />

          <Sidebar
            content={SidebarControlAppRoleFunctions}
            user={currentUser}
            setLogin={setLogin}
            currentFunction={currentFunction}
            setCurrentFunction={setCurrentFunction}
          />
          {currentFunction === "User" ? (
            <>
              <UserManagement />
            </>
          ) : (
            <></>
          )}
          {currentFunction === "Team" ? (
            <Team currentUser={currentUser} />
          ) : (
            <></>
          )}
          {currentFunction === "Aufgaben" ? <Aufgaben /> : <></>}
          {currentFunction === "Dashboard" ? <Dashboard /> : <></>}
        </div>
      )}
    </div>
  );
}

export default App;
