import { useState, useEffect } from "react";

export default function Aufgaben() {
  // Main usermanagement return
  return (
    <div className="Function-Screen">
      <div className="Function-Screen-header">
        <h2>AUFGABEN</h2>
      </div>
      <div className="Function-Screen-main"></div>
    </div>
  );
}
