import { useEffect, useState } from "react";
import supabaseCLP from "../supabase";
// bootstrap stuff
import "bootstrap/dist/css/bootstrap.min.css";

export default function UserManagement() {
  const [userlist, setUserlist] = useState([]);
  const [searchOption, setSearchOption] = useState("");
  const [searchString, setSearchString] = useState("");
  const [filteredUserlist, setFilteredUserlist] = useState([]);
  const [uniqueDepartments, setUniqueDepartments] = useState([]);
  const [sortedUsernames, setSortedUsernames] = useState([]);

  // Table states
  const [selectedCell, setSelectedCell] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [clickedCellIndex, setClickedCellIndex] = useState(null);
  const [editCell, setEditCell] = useState(false);

  function handleCellClick(rowIndex, cellIndex, value) {
    setClickedRowIndex(rowIndex);
    setClickedCellIndex(cellIndex);
    setSelectedCell(String(value));

    console.log(selectedCell);
  }

  const requiredColumns = "username,password,approved,department,role,email";

  async function getUserManagementlist() {
    console.log("Fetching user data...");
    try {
      // load all data
      const { data: UserDB, error } = await supabaseCLP
        .from("Userlist")
        .select(requiredColumns);
      setUserlist(UserDB);
      setFilteredUserlist(UserDB);

      // get unique departments
      setUniqueDepartments([...new Set(UserDB.map((item) => item.department))]);

      // sorted usernames
      setSortedUsernames(UserDB.map((e) => e.username.toLowerCase()).sort());
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  useEffect(() => {
    getUserManagementlist();
  }, []);

  // submitting the search form
  function onSubmitSearch(e) {
    e.preventDefault();

    FilterUserlist();
  }

  function Loader() {
    return (
      <>
        <h3>loading...</h3>
        {/* <img
          style={{ width: "200px" }}
          src="./Icons/spinningwheel.gif"
          alt="spinningloadericon"
          onLoadedData={console.log("finished loading")}
        /> */}
      </>
    );
  }

  function SearchBar() {
    const [showFilter, setShowFilter] = useState(searchOption !== "");
    console.log("current showFilter: " + showFilter);
    return (
      <div className="tableSearchBar">
        {!showFilter ? (
          <>
            <span
              className="material-symbols-outlined "
              onClick={() => setShowFilter(!showFilter)}
            >
              filter_list
            </span>
          </>
        ) : (
          <>
            <span
              className="material-symbols-outlined active-icon"
              onClick={() => {
                setShowFilter(!showFilter);
                setSearchOption("");
                setSearchString("");
                setFilteredUserlist(userlist);
              }}
            >
              filter_list_off
            </span>
          </>
        )}
        <h3>FILTERN</h3>
        <form onSubmit={onSubmitSearch}>
          <div className="tableSearchBar-Input">
            {showFilter ? (
              <>
                <p>nach </p>

                <select
                  onInput={(e) => {
                    setSearchOption(e.target.value);
                    console.log("rushed here to select at categorie...");
                  }}
                  // onChange={(e) => setSearchOption(e.target.value)}
                  value={searchOption}
                >
                  <option value=""></option>
                  <option value="username">Username</option>
                  <option value="email">Email</option>
                  <option value="department">Abteilung</option>
                  <option value="role">Role</option>
                  <option value="approved">Approved</option>
                </select>
              </>
            ) : (
              <></>
            )}

            {searchOption === "username" && (
              <select
                key="SearchFieldUsername"
                onInput={(e) => setSearchString(e.target.value)}
                value={searchString}
              >
                <option value=""></option>
                {sortedUsernames.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            )}
            {searchOption === "email" && (
              <select
                key="SearchFieldEmail"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
              >
                <option value=""></option>
                {userlist.map((e) => (
                  <option value={e.email}>{e.email}</option>
                ))}
              </select>
            )}
            {searchOption === "department" && (
              <select
                key="SearchFieldDepartment"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
              >
                <option value=""></option>
                {uniqueDepartments.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            )}

            {searchOption === "role" && (
              <select
                key="SearchFieldRole"
                required
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              >
                <option value=""></option>
                <option value="admin">admin</option>
                <option value="manager">manager</option>
                <option value="operator">operator</option>
              </select>
            )}
            {searchOption === "approved" && (
              <select
                key="SearchFieldApproved"
                required
                value={String(searchString)}
                onChange={(e) => setSearchString(e.target.value)}
              >
                <option value=""></option>
                <option value={true}>true</option>
                <option value={false}>false</option>
              </select>
            )}
          </div>
          <div className="tableSearchBar-Buttons">
            {searchOption ? <button>filtern</button> : <></>}
          </div>
        </form>
      </div>
    );
  }

  function EditLine() {
    return (
      <div
        style={{ display: "flex", columnGap: "20px", paddingBottom: "20px" }}
      >
        {!editCell ? (
          <span
            className="material-symbols-outlined"
            onClick={(e) => setEditCell(true)}
          >
            edit_note
          </span>
        ) : (
          <span
            className="material-symbols-outlined active-icon"
            onClick={(e) => {
              setEditCell(false);
              setSelectedCell(null);
            }}
          >
            edit_off
          </span>
        )}
        <h3>EDITIEREN</h3>
      </div>
    );
  }

  function FilterUserlist() {
    // if (searchOption && searchString) {

    try {
      const filtereduserlist = userlist.filter((e) => {
        // Use .toLowerCase() to make the search case-insensitive
        const searchField = String(e[searchOption])
          ? String(e[searchOption]).toLowerCase()
          : "";
        const searchValue = String(searchString.toLowerCase());

        // Use .includes() for a more flexible search
        return searchField.includes(searchValue);
      });

      setFilteredUserlist(filtereduserlist);
    } catch (error) {
      console.log(error);
      setFilteredUserlist(userlist);
    }
    // }
  }

  function ModalFormUserEdit() {
    const [updatedValue, setUpdatedValue] = useState("");
    const currentColName = requiredColumns
      .split(",")
      [clickedCellIndex].replace(/ /g, "");
    const currentUserName = filteredUserlist[clickedRowIndex].username;

    async function handleSupabaseUpdate() {
      console.log("supabase col name: ", currentColName);
      console.log("username: ", currentUserName);
      console.log("the updated value: ", updatedValue);

      const { data, error } = await supabaseCLP
        .from("Userlist")
        .update({
          [currentColName]: updatedValue,
        })
        .eq("username", currentUserName)
        .select();

      console.log("neuer Werte für supabase: ", updatedValue);
      // reload updated data
      if (updatedValue != null) {
        if (String(updatedValue).length > 0) {
          getUserManagementlist();

          // reset all after updating
          setSelectedCell(null);
          setEditCell(false);
          setSearchOption("");
          setSearchString("");
          setFilteredUserlist(userlist);
        } else {
          alert("keine Änderung erkannt. Abbrechen?");
        }
      }
    }

    if (!String(selectedCell) && !editCell) {
      console.log("no row selected");
      return <p> select a row to edit a user</p>;
    }

    function editField_Role() {
      return (
        <select
          value={updatedValue}
          required
          onInput={(e) => setUpdatedValue(e.target.value)}
        >
          <option value="---">---</option>
          <option value="admin">admin</option>
          <option value="manager">manager</option>
          <option value="operator">operator</option>
        </select>
      );
    }

    function editField_Approved() {
      return (
        <select
          value={String(updatedValue)}
          required
          onInput={(e) => setUpdatedValue(e.target.value)}
        >
          <option value={"---"}>---</option>
          <option value={true}>true</option>
          <option value={false}>false</option>
        </select>
      );
    }

    function editField_Text() {
      return (
        <input
          type="text"
          required
          placeholder={selectedCell}
          value={updatedValue}
          onInput={(e) => setUpdatedValue(e.target.value)}
        ></input>
      );
    }

    console.log("Einträge der Spalte", currentColName, "editieren:");

    return (
      <>
        <div className="modalFormUserEdit">
          <p>
            editieren der Spalte{" "}
            <span style={{ fontWeight: "600" }}>{currentColName}</span>
          </p>
          <p>
            des users{" "}
            <span style={{ fontWeight: "600" }}>{currentUserName}</span>
          </p>

          <p>
            von{" "}
            <span style={{ fontWeight: "600" }}>{String(selectedCell)}</span>
          </p>
          <p></p>
          <div>
            nach {/* select type of input field */}
            {currentColName === "approved" ? editField_Approved() : <></>}
            {currentColName === "role" ? editField_Role() : <></>}
            {currentColName === "password" ||
            currentColName === "department" ||
            currentColName === "email" ? (
              editField_Text()
            ) : (
              <></>
            )}
            <button onClick={() => handleSupabaseUpdate()}>Update</button>
          </div>
        </div>
      </>
    );
  }

  function Table({ userlist }) {
    if (!userlist.length > 0) {
      return <p>Ihre Suche ergab keine Treffen...</p>;
    } else {
      return (
        <div className="FrameAroundTable">
          <table
            className="styled-table"
            style={{ border: editCell ? "3px #097ec2 solid" : "" }}
          >
            <thead>
              <tr>
                {Object.keys(userlist[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userlist.map((elem, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={{
                    backgroundColor:
                      rowIndex === clickedRowIndex && editCell
                        ? "##097ec23d"
                        : "",
                  }}
                >
                  {Object.values(elem).map((value, cellIndex) => (
                    <td
                      key={cellIndex}
                      onClick={() =>
                        handleCellClick(rowIndex, cellIndex, value)
                      }
                      style={{
                        backgroundColor:
                          rowIndex === clickedRowIndex &&
                          cellIndex === clickedCellIndex &&
                          editCell
                            ? "#097ec2"
                            : "",
                        color:
                          rowIndex === clickedRowIndex &&
                          cellIndex === clickedCellIndex &&
                          editCell
                            ? "#fff"
                            : "",
                      }}
                    >
                      {String(value)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }

  function AddNewUser() {
    const [showAddUser, setShowAddUser] = useState(false);

    const reqUserAttributList = requiredColumns.split(",");

    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newDepartment, setNewDepartment] = useState("");
    const [newRole, setNewRole] = useState("");
    const [newStatus, setNewStatus] = useState("");

    async function handleSupabaseInsert(newUser) {
      // add error is username already exists...

      console.log(newUser);
      try {
        const { data, error } = await supabaseCLP
          .from("Userlist")
          .insert([newUser])
          .select();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }

    // submitting the search form
    function onSubmitNewUser(e) {
      e.preventDefault();

      const newUser = {
        username: newUsername,
        password: newPassword,
        department: newDepartment,
        email: newEmail,
        role: newRole,
        approved: newStatus.toLocaleUpperCase(),
        workbench: "tbd",
        created: new Date().toLocaleString(),
      };
      handleSupabaseInsert(newUser);
      setShowAddUser(false);

      // update page
      getUserManagementlist();
    }

    return (
      <div className="addNewUser">
        <div className="addNewUser-Header">
          {!showAddUser ? (
            <span
              className="material-symbols-outlined"
              onClick={() => setShowAddUser(true)}
            >
              person_add
            </span>
          ) : (
            <span
              className="material-symbols-outlined active-icon"
              onClick={() => setShowAddUser(false)}
            >
              cancel
            </span>
          )}
          <h3>HINZUFÜGEN</h3>
        </div>
        <div>
          {showAddUser ? (
            <form className="addNewUser-Main" onSubmit={onSubmitNewUser}>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>username</label>
                </div>
                <div>
                  <input
                    type="text"
                    required
                    placeholder="username"
                    onChange={(e) => setNewUsername(e.target.value)}
                    value={newUsername}
                  />
                </div>
              </div>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>password</label>
                </div>
                <div>
                  <input
                    type="text"
                    required
                    placeholder="password"
                    onInput={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                  />
                </div>
              </div>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>department</label>
                </div>
                <div>
                  <input
                    type="text"
                    required
                    placeholder="department"
                    onInput={(e) => setNewDepartment(e.target.value)}
                    value={newDepartment}
                  />
                </div>
              </div>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>email</label>
                </div>
                <div>
                  <input
                    type="email"
                    required
                    placeholder="name@company.de"
                    value={newEmail}
                    onInput={(e) => setNewEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>apporved</label>
                </div>
                <div>
                  <select
                    value={String(newStatus)}
                    required
                    onInput={(e) => setNewStatus(e.target.value)}
                  >
                    <option value="-">---</option>
                    <option value={false}>false</option>
                    <option value={true}>true</option>
                  </select>
                </div>
              </div>
              <div className="addNewUser-Main-Line">
                <div>
                  <label>Role</label>
                </div>
                <div>
                  <select
                    value={String(newRole)}
                    required
                    onChange={(e) => setNewRole(e.target.value)}
                  >
                    <option value="-">---</option>
                    <option value="operator">operator</option>
                    <option value="manager">manager</option>
                    <option value="admin">admin</option>
                  </select>
                </div>
              </div>

              <div
                className="addNewUser-Main-Line"
                style={{ justifyContent: "flex-end" }}
              >
                <div></div>
                <div>
                  <button>erstellen</button>
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  // Main usermanagement return
  return (
    <div className="Function-Screen">
      <div className="Function-Screen-header">
        <h2>USER MANAGEMENT</h2>
      </div>
      <div className="Function-Screen-main">
        <AddNewUser />
        <SearchBar />

        {userlist.length > 0 ? (
          <>
            <Table userlist={filteredUserlist} />
            <EditLine />
            {selectedCell ? <ModalFormUserEdit /> : <></>}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
