export default function Sidebar({
  content,
  user,
  setLogin,
  currentFunction,
  setCurrentFunction,
}) {
  function PermittedFunctions(elem, user) {
    // console.log(content);
    return elem.reqRole.findIndex((e) => e === user.Role) > -1;
  }

  function RoleColor(user) {
    if (user.Role === "admin") return "red";
    if (user.Role === "manager") return "orange";
    if (user.Role === "operator") return "darkgrey";
  }

  return (
    <div className="sidebar">
      <div className="logo disabled-hover">
        <img src="./Icons/CLP_Logo_Black.png" alt="sidebar-logo" />
      </div>
      <div className="rows">
        <hr />
        {content.map((elem) =>
          PermittedFunctions(elem, user) ? (
            <div
              key={`${elem.id}-${elem.description}`}
              onClick={() => setCurrentFunction(elem.description)}
              className="sidebar-row"
            >
              {/* {console.log(elem.description)} */}
              <div>
                <img
                  onClick={() => setCurrentFunction(elem.description)}
                  style={{
                    borderLeft:
                      currentFunction === elem.description
                        ? "#097ec2 4px solid"
                        : "none",
                    filter:
                      currentFunction === elem.description
                        ? "opacity(1)"
                        : "opacity(0.3)",
                  }}
                  src={elem.img}
                  alt={elem.description}
                />
              </div>
              <div>
                <p
                  style={{
                    color:
                      currentFunction === elem.description ? "#000" : "#bbb",
                  }}
                >
                  {elem.description}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )
        )}
        <hr />
      </div>
      <div className="user">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "5px",
            alignItems: "center",
          }}
        >
          <img
            src="./Icons/Symbole/User_Black.png"
            alt="UserIcon"
            style={{ backgroundColor: RoleColor(user), borderRadius: "20px" }}
          />

          <p style={{ color: RoleColor(user) }}>{user.Role}</p>
        </div>
        <div>
          <p>{user.userName}</p>
        </div>

        <span
          className="material-symbols-outlined"
          id="logoutIcon"
          src="./Icons/Symbole/Verlassen_Black.png"
          alt="logoutIcon"
          onClick={() => {
            setLogin(false);
            setCurrentFunction("");
            localStorage.clear();
          }}
        >
          logout
        </span>
      </div>
    </div>
  );
}
